import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Works } from '../../interface'
import { scrollHandler } from '../../utils/functions'


class PublicationsDetail extends React.Component<{data?:Works}> {

	works:any = {};


  constructor(props:any) {
    super(props);
    
    if(this.props.data)
    this.works = this.props.data;

  }

  componentDidMount() {
    scrollHandler();    
  }

	render(){

    let images = this.works.acf.works_images;
    let pages = [];
    let coverImageElem;
    let pageBody;
    let classNames = '';

    if(images && images.length){
      
      classNames = 'page post-cover col-' + images[0].works_images_image_number_of_columns
      let classNames2 = 'image col-' + images[0].works_images_image_number_of_columns
      let coverImage = images[0].works_images_image.url.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')

      coverImageElem = <React.Fragment>
        <div className={classNames2}>
          <img src={coverImage} alt=""/>
          <div className="image-cover"></div>
        </div>
        <div className="caption" dangerouslySetInnerHTML={{ __html: images[0].works_images_image_caption }} />
        </React.Fragment>

      for(var i = 1; i <images.length; i++){
        let pageclassNames = 'page post-cover col-' + images[i].works_images_image_number_of_columns
        let imageClassNames = 'image col-' + images[i].works_images_image_number_of_columns
        let image = images[i].works_images_image.url.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
        pages.push(
          <div className={pageclassNames} key={i}>
            <div className="inner">
            <div className={imageClassNames}>
              <img src={image} alt=""/>
              <div className="image-cover"></div>
            </div>
            <div className="caption" dangerouslySetInnerHTML={{ __html: images[i].works_images_image_caption }} />
            </div>
          </div>
        );
      }      

    } else {
      let featuredImage = this.works.featured_image_src.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
      coverImageElem = <div className="page post-cover">
      <img src={featuredImage} alt=""/>
      </div>
    }

    if(this.works.content){
      pageBody = <div className="page post-body">
        <div className="inner">
          <div dangerouslySetInnerHTML={{ __html: this.works.content }} />
        </div>
      </div>
    }

    let subtitle = this.works.acf.subtitle ? '<br />' + this.works.acf.subtitle : '';
    let sectionClass = this.works.acf.subtitle ? 'contents-section has-subtitle' : 'contents-section';

		return (  
      <section className={sectionClass}>
        <div className="container">      
          <h1 className="page-title">
            {this.works.title}{ReactHtmlParser(subtitle)}
          </h1>
          <div className="page-contents">
            <div className="pages">
              <div className={classNames}>
                <div className="inner">
                  {coverImageElem}       
                </div>       
              </div>
                {pageBody} 
                {pages}
            </div>
          </div>
        </div>
      </section>
    )
  }

}


export default PublicationsDetail
