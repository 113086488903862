import * as React from 'react'
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import PublicationsDetail from '../../components/publications/PublicationsDetail'
import { Works } from '../../interface'
import SEO from '../../components/seo'


type Props = {
  items: Works[]
  pathname: string,
  data: any
}


class PublicationsDetailPage extends React.Component<Props> {

  data:any;

  constructor(props: Props) {
    super(props);
	
    // this.title = props.title ? props.title + ' - ' : '';	
    // this.children = props.children;

  }


  render() {

    return <Layout>
      <SEO title={this.props.data.wordpressWpPublications.title} />
      {this.props.data.wordpressWpPublications && <PublicationsDetail data={this.props.data.wordpressWpPublications} />}
    </Layout>
  
  }
}

export default PublicationsDetailPage



export const query = graphql`
      query($slug: String!) { 
        __typename
        wordpressWpPublications(slug: { eq: $slug }) {
          content
          featured_image_src
          title
          acf {
            subtitle
            works_images {
              works_images_image {
                url
              }
              works_images_image_caption
              works_images_image_number_of_columns
            }
          }
        }        
      }

    `;
